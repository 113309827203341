import { Suspense, lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'

// LAYOUTS
import Dashboard from 'src/wuling/layouts/Dashboard/Dashboard'

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return(
        <Suspense
            fallback={
                <div/>
            }
        >
            <Component {...props}/>
        </Suspense>
    )
}

// PAGES (SORTED BY ORDER)
const Sim = Loadable(lazy(() => import('src/wuling/pages/Sim/Sim')))
const Accessories = Loadable(lazy(() => import('src/wuling/pages/Accessories/Accessories')))

const Router = () => {
    return useRoutes([
        // DASHBOARD ROUTES
        {
            path: 'dashboard',
            element: <Dashboard/>,
            children: [
                {
                    path: '/',
                    element: <Navigate to='/dashboard/sim' replace/>,
                },
                {
                    path: '/sim',
                    element: <Sim/>,
                },
                {
                    path: '/accessories',
                    element: <Accessories/>,
                },
            ],
        },
        {
            path: 'user',
            element: <Dashboard/>,
            children: [
                {
                    path: '/',
                    element: <Navigate to='/dashboard/user/one' replace />
                },
                { 
                    path: 'one', 
                    element: <Sim/> 
                },
                { 
                    path: 'two', 
                    element: <Accessories/> 
                },
            ]
        }
    ])
}

export default Router