function path(root, sublink) {
    return `${root}${sublink}`
}
  
const ROOTS_DASHBOARD = '/dashboard'
    
export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    dashboard: {
        sim: path(ROOTS_DASHBOARD, '/sim'),
        accessories: path(ROOTS_DASHBOARD, '/accessories'),
    },
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        one: path(ROOTS_DASHBOARD, '/user/one'),
        two: path(ROOTS_DASHBOARD, '/user/two'),
    }
}
  