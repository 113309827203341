// COMPONENTS
import SvgIconStyle from './SvgIconStyle'

// PATHS
import { PATH_DASHBOARD } from 'src/wuling/routes/paths'

const getIcon = (name) => (
	<SvgIconStyle 
		src={`/static/icons/navbar/${name}.svg`} 
		sx={{ width: '100%', height: '100%' }} 
	/>
)

const ICONS = {
	user: getIcon('ic_user'),
	ecommerce: getIcon('ic_ecommerce'),
	analytics: getIcon('ic_analytics'),
	dashboard: getIcon('ic_dashboard'),
}

const sidebarConfig = [
	{
		subheader: 'Devices',
		items: [
			{ 
				title: 'Sim', 
				path: PATH_DASHBOARD['dashboard']['sim'], 
				icon: ICONS['user'] 
			},
			{ 
				title: 'Accessories', 
				path: PATH_DASHBOARD['dashboard']['accessories'], 
				icon: ICONS['ecommerce'] 
			},
		]
	},
	{
		subheader: 'Management',
		items: [
			{
				title: 'User',
				path: PATH_DASHBOARD['user']['root'],
				icon: ICONS['user'],
				children: [
					{ 
						title: 'One', 
						path: PATH_DASHBOARD['user']['one'] 
					},
					{ 
						title: 'Two', 
						path: PATH_DASHBOARD['user']['two'] 
					},
				]
			}
		]
	}
]

export default sidebarConfig
