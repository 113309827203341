import React from 'react'

// MATERIAL UI CORE
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

// ROUTER
import Router from 'src/wuling/routes/Router'

const App = () => {
    const theme = createTheme({
        palette: {
            primary: {
                name: 'blue',
                lighter: '#CCDFFF',
                light: '#6697FF',
                main: '#0045FF',
                dark: '#0027B7',
                darker: '#00137A',
                contrastText: '#fff'
            },
        },
        typography: {
            fontFamily: [
                'Poppins', 'sans-serif'
            ].join(','),
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <Router/>
        </ThemeProvider>
    )
}

export default App