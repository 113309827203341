import React, { useState, useContext } from 'react'
import { Outlet } from 'react-router-dom'

// COMPONENTS
import DashboardSidebar from 'src/wuling/components/Sidebar/DashboarSidebar'

// CONTEXTS
import { CollapseDrawerContext } from 'src/wuling/contexts/CollapseDrawerContext'

// MATERIAL UI CORE
import { styled, useTheme } from '@material-ui/core/styles'

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
})

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

const Dashboard = () => {
    const theme = useTheme()
    const { collapseClick } = useContext(CollapseDrawerContext)
    const [open, setOpen] = useState(false)

    return (
        <RootStyle>
            <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle
                sx={{
                    transition: theme.transitions.create('margin', {
                        duration: theme.transitions.duration.complex
                    }),
                    ...(collapseClick && {
                        ml: '102px'
                    })
                }}
            >
                <Outlet />
            </MainStyle>
        </RootStyle>
    )
}

export default Dashboard